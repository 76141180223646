@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@100;300;400;500;600;700&display=swap');
@import '../src/theme.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  font-family: 'Urbanist', sans-serif;
  margin: 0;
  padding: 0;
  background: #fff;
  padding-top: 7rem;

  @media (max-width:991px) {
    padding-top: 80px;
  }
}

.container {
  max-width: 1280px !important;
 
  padding: 0 !important;
  @media(max-width:1330px){
    width: calc(100% - 80px) !important;
  }
}

.remove-body-padding {
  padding-top: 0;
}

.header-bg-blue {
  background-color: rgb(229, 240, 255, 0.4);
}

.swiper-slide {
  align-self: stretch;
  height: auto !important;
}

.error {
  width: 100%;
  margin-top: 1px;
  font-size: .875rem;
  color: $danger;
  padding: 0 1.5rem;
}

.auth-container {
  width: 100%;
  min-height: calc(100dvh - 115px);
  display: flex;
  align-items: center;
  justify-content: center;

  .auth-wrapper {
    max-width: 638px;
    width: calc(100% - 80px);

    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .auth-top {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;

      .auth-title-wrap {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 2px;

        .auth-title {
          font-size: 3rem;
          font-style: normal;
          font-weight: 700;
          line-height: 125%;
          letter-spacing: -0.96px;

          @media(max-width:767px) {
            font-size: 1.5rem;
          }
        }

        .auth-decription {
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }

      .lang-wrapper-main {
        flex: 0 0 auto;

        .lang-wrap {
          border: 1px solid #E7E7E7;
          border-radius: 4rem;
          display: flex;
          align-items: center;
          padding: 0.8rem 1.5rem;
          gap: 0.8rem;

          .flag-wraper {
            flex: 0 0 24px;
            width: 24px;
            height: 24px;
            border-radius: 24px;
            overflow: hidden;
          }

          .lang-name-wrap {
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 600;
            line-height: 155.556%;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }

            @media(max-width:767px) {
              font-size: 1rem;
            }
          }

          @media(max-width:767px) {
            padding: 0.8rem 1rem;
            gap: 0.5rem;

          }
        }
      }
    }

    @media(max-width:991px) {
      max-width: 100% !important;
    }
  }

}

.form-row {
  position: relative;

  .form-control {
    padding-right: 2.8rem;
  }

  .icon-wrap {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 3.1875rem;
    right: 1rem;
    z-index: 1;

  }
}

.custom-checkbox {
  display: inline-block;
  position: relative;
}

.custom-checkbox input[type="checkbox"] {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.custom-checkbox input[type="checkbox"]+i {
  display: none;
  position: absolute;
  top: -1px;
  left: 3px;
  width: 24px;
  height: 24px;
}

.custom-checkbox label {
  position: relative;
  cursor: pointer;
  padding-left: 32px;

}

.custom-checkbox label:before {
  content: '';
  border: 1px solid #E7E7E7;
  position: absolute;
  cursor: pointer;
  margin-right: 8px;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  display: inline-block;
  left: 0;
  top: 0;
}

.custom-checkbox input[type="checkbox"]:checked+i {
  display: inline-block;
}

.react-tel-input {
  .form-control {
    padding: 1rem 1.5rem 1rem 3rem !important;
    font-size: 1rem !important;
    font-weight: 400;
    line-height: 1.5 !important;
    border: 1px solid #E7E7E7 !important;
    border-radius: 5.625rem !important;
    width: 100% !important;
    height: auto !important;
  }
}

.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: none !important;

  .selected-flag {
    padding: 0 !important;
    width: 50px !important;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: transparent !important;
    }
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  .form-control {
    width: 100%;
    cursor: pointer;
  }
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  fill: $white !important;
  color: $white !important;
  stroke: $white !important;
}

.react-datepicker {
  border: unset !important;
  box-shadow: 0px 2.365px 11.825px 0px rgba(0, 0, 0, 0.10);

}


.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  content: none !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  left: -20px !important;
  border-bottom-color: $white !important;
}

.react-datepicker__time-list-item--selected {
  background: $primary !important;
}

.react-datepicker__time-list-item {
  &:hover {
    background: $primary !important;
    color: $white !important;
  }
}

.react-datepicker__header {
  background: $white !important;
  border-bottom-color: #E5E5E5;
}

.react-datepicker.react-datepicker--time-only,
.react-datepicker--time-only .react-datepicker__time-container,
.react-datepicker__time-box {
  width: 100% !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--today {
  background: $primary !important;
  color: $white !important;
}

.react-datepicker__day--today {
  background: $primary !important;
  border-radius: 4px;
  color: $white !important;
}