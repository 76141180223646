@import '../../theme.scss';

.payment-wrapper-main {
    width: 100%;
    display: flex;
    .btn-wrap {
        .btn-light {
            border-color: #E7E7E7;
            background-color: $white;
            display: flex;
            align-items: center;
            gap: 0.8rem;

            svg {
                fill: currentColor;
            }

            &:hover {
                background: $primary;
                color: $white;
                border-color: $primary;
            }
        }

        &.back-btn {
            display: none;

            @media(max-width:767px) {
                display: block;
                padding: 2.5rem 1.25rem 2rem;
            }
        }
    }

    .payment-left {
        flex: 1 1 867px;
        background-color: $white;
        display: flex;
        padding: 5rem 2.5rem 5rem 5rem;
        order: 0;


        .payment-left-content {
            flex: 0 1 628px;
            display: flex;
            flex-direction: column;
            gap: 2rem;



            .payment-title-wrap {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;

                .payment-title {
                    font-size: 2.25rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 122.222%;
                    letter-spacing: -0.72px;

                    @media(max-width:767px) {
                        font-size: 1.5rem;
                    }
                }

                .payment-description {
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                }

            }

            .payment-form-wrap {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                gap: 2rem;

                hr {
                    border-color: #bbbaba;
                }

                .form-row {
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: column;

                    .date-wrapper {
                        .form-control {
                            background: url(../../assets//images/icons/calendar.svg) no-repeat center right 20px;
                        }
                    }

                    .time-wrapper {
                        .form-control {
                            text-align: center !important;
                        }
                    }

                    .form-label {
                        margin-bottom: 1rem;
                        line-height: 100%;
                        font-size: 1.125rem;
                    }

                    .card-wrapper {
                        position: relative;

                        .card-tag-wrap {
                            position: absolute;
                            top: 1rem;
                            right: 1.25rem;
                            z-index: 1;
                            display: flex;
                            gap: .625rem;

                            .card-tag {
                                width: 35px;
                                height: 24px;
                                border: 1px solid #E7E7E7;
                                border-radius: 4px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }

                        .form-control {
                            padding-right: 6.5rem;
                        }
                    }
                }

                .installation-address-wrap {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    .installation-title {
                        font-size: 1.5rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 133.333%;

                        @media(max-width:767px) {
                            font-size: 1.25rem;
                        }
                    }
                }

            }

            @media(max-width:767px) {
                flex: 1 1 100%;

                .btn-wrap {
                    display: none;
                }
            }
        }

        @media(max-width:991px) {
            padding: 2.5rem 1.25rem;
            flex: 1 1 50%
        }

        @media(max-width:767px) {
            order: 1;
            flex: 1 1 100%;
        }
    }

    .payment-right {
        flex: 1 1 573px;
        background: $lightblue;
        display: flex;
        justify-content: center;
        padding: 5rem 2.5rem;
        align-self: stretch;
        order: 1;

        .payment-right-content {
            flex: 0 1 412px;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            .payment-order-title {
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 600;
                line-height: 133.333%;
            }

            .order-box-wrapper {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 1rem;

                .order-box {
                    width: 100%;
                    border-radius: 1.125rem;
                    border: 1px solid #E7E7E7;
                    background: #FFF;
                    padding: 1rem;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    height: 100%;
                    &.active{
                        border-color: $primary;
                    }

                    .order-box-top {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        gap: .625rem;
                        align-items: center;

                        .order-tag {
                            border-radius: 4rem;
                            padding: .625rem 1.5rem;
                            font-size: 1rem;
                            font-weight: 600;
                            border: 1px solid #E7E7E7;
                            color: $primary;
                            line-height: 150%;
                        }

                        .order-select-wrap {
                            flex: 0 0 40px;

                            .custom-checkbox {
                                width: 40px;
                                height: 40px;
                                position: relative;
                                border-radius: 40px;
                                background-color: $white;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border: 1px solid #E7E7E7;
                                cursor: pointer;

                                svg {
                                    width: 16px;
                                    display: none;
                                }
                            }

                            input[type="checkbox"] {
                                visibility: hidden;
                                opacity: 0;
                                position: absolute;
                                top: 0;
                                left: 0;
                                z-index: 0;


                                &:checked+.custom-checkbox {
                                    width: 40px;
                                    height: 40px;
                                    position: relative;
                                    border-radius: 40px;
                                    background-color: $lightblue;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    border: 1px solid $lightblue;

                                    svg {
                                        width: 16px;
                                        display: block;
                                    }


                                }
                            }


                        }
                    }

                    .order-description {
                        width: 100%;
                        font-size: 1.125rem;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 155.556%;
                        min-height: 84px;
                    }

                    .order-footer {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        gap: .625rem;
                        align-items: center;
                        margin-top: auto;

                        .order-price {
                            flex: 0 0 auto;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 150%;
                        }

                        .learn-more {

                            flex: 0 0 96px;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 155.556%;
                            text-decoration-line: underline;
                            color: $primary;
                            cursor: pointer;

                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }
            }

        }

        @media(max-width:991px) {
            padding: 2.5rem 1.25rem;
            flex: 1 1 50%
        }

        @media(max-width:767px) {
            order: 0;
            flex: 1 1 100%;
        }
    }

    @media(max-width:767px) {
        flex-direction: column;

    }
}