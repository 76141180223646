.aboutus-wrapper {
    width: 100%;
    height: auto;
    padding-top: 5rem;
    display: flex;
    flex-direction: column;
    gap: 5rem;

    .about-content-wrapper {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .about-section-title {
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 700;
            line-height: 133.333%;
            color: #0068FF;

            @media(max-width:991px) {
                font-size: 1.125rem;
            }

            @media(max-width:767px) {
                font-size: 1rem;
            }
        }

        .about-content {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .about-title {
                font-size: 3.75rem;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                letter-spacing: -1.2px;

                @media(max-width:1199px) {
                    font-size: 3rem;
                }

                @media(max-width:767px) {
                    font-size: 1.5rem;
                }
            }

            .about-description {
                max-width: 737px;
                width: 100%;
                font-size: 1rem;
            }
        }

        @media(max-width:767px) {
            gap: 1rem;
        }
    }

    .about-bottom-wrap {
        width: 100%;


        .about-bottom-section {
            width: 100%;
            height: auto;
            display: flex;
            gap: 1.5rem;
            overflow: hidden;
            height: 370px;
            position: relative;

            .about-frame-wrap {
                flex: 0 1 737px;
                order:0;

                img {
                    width: 100%;
                }

                @media(max-width:991px) {
                    flex: 0 1 500px;
                }

                @media(max-width:767px) {
                    flex: 1 1 auto;
                    order: 1;
                }
            }

            .car-img-wrap {
                flex: 0 1 500px;
                border-radius: 1.25rem;
                border-bottom-left-radius:0 ;
                border-bottom-right-radius:0 ;
                background: #E5F0FF;
                align-items: center;
                justify-content: center;
                display: flex;
                overflow: hidden;
                order: 1;

                img {
                    width: 100%;
                }

                @media(max-width:991px) {
                    flex: 0 1 700px;
                    height: 320px;
                    border-radius: 1.25rem !important;
                }

                @media(max-width:767px) {
                    flex: 1 1 auto;
                    height: auto;
                    order: 0;
                }
            }

            @media(max-width:991px) {
                height: auto;
                padding-bottom: 2.5rem;
                align-items: flex-end;
            }

            @media(max-width:767px) {
                flex-direction: column;
            }
        }

    }

    @media(max-width:991px) {
       padding-top: 2.5rem;
    }

    @media(max-width:767px) {
        gap: 1.875rem;
        padding-top: 2rem;
    }

}