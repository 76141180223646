@import '../../../theme.scss';

.our-services-wrapper {
    width: 100%;
    height: auto;
    background: #000;
    padding: 7.75rem 0 5rem;
   

    .os-content-container {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        gap: 5rem;

        .os-heading-wrap {
            max-width: 410px;
            width: 100%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .os-heading {
                font-size: 3rem;
                font-style: normal;
                font-weight: 700;
                line-height: 125%;
                letter-spacing: -0.96px;
                color: $white;
                text-align: center;

                @media(max-width:991px) {
                    font-size: 2.25rem;
                }

                @media(max-width:767px) {
                    font-size: 1.5rem;
                }
            }

            .os-description {
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.5rem;
                text-align: center;
                color: $white;
            }
        }

        .os-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 5rem;

            .os-row {
                width: 100%;
                height: auto;
                display: flex;
                align-items: center;
                gap: 2.5rem;

                .os-img-wrap {
                    flex: 0 1 620px;
                    overflow: hidden;
                    border-radius: 1.5rem;

                    img {
                        width: 100%;
                    }

                    @media(max-width:767px) {
                        flex: 1 1 auto;
                        width: 100%;
                    }
                }

                .os-wrapper {
                    flex: 1 1 620px;
                    display: flex;
                    flex-direction: column;
                    gap: 1.5rem;

                    .os-tag {
                        width: 100%;

                        span {
                            border-radius: 4rem;
                            padding: .75rem 1.5rem;
                            font-size: 1rem;
                            font-weight: 600;
                            border: 1px solid #3B3D41;
                            color: $white;
                            display: inline-block;
                        }
                    }

                    .os-info-wrap {
                        width: 100%;
                        display: flex;
                        flex-direction: column;

                        .os-title-wrap {
                            font-size: 3rem;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 125%;
                            letter-spacing: -0.96px;
                            color: $primary;

                            span {
                                color: $white;
                            }

                            @media(max-width:1199px) {
                                font-size: 2.5rem;
                                line-height: 133.333%;
                            }

                            @media(max-width:767px) {
                                font-size: 1.5rem;
                                line-height: 133.333%;
                            }
                        }
                    }

                    .os-list-wrap {
                        width: 100%;
                        height: auto;
                        display: flex;
                        flex-direction: column;
                        gap: 0.5rem;

                        .os-list-row {
                            width: 100%;
                            height: auto;
                            display: flex;
                            gap: 0.5rem;

                            .os-list-icon {
                                flex: 0 0 24px;
                            }

                            .os-list-content {
                                font-size: 1rem;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 1.5rem;
                                color: $white;
                            }
                        }
                    }

                    @media(max-width:767px) {
                        flex: 1 1 auto;
                        width: 100%;
                    }
                }

                &.vehicle-alert {
                    .os-img-wrap {
                        order: 1;

                        @media(max-width:767px) {
                            order: 0;
                        }
                    }

                    .os-wrapper {
                        order: 0;

                        @media(max-width:767px) {
                            order: 1;
                        }
                    }
                }

                @media(max-width:767px) {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }

            @media(max-width:767px) {
                gap: 2.5rem;
            }
        }

        @media(max-width:991px) {
            gap: 4rem;
        }

        @media(max-width:767px) {
            gap: 2.25rem;
            
        }

    }

    @media(max-width:767px) {
        padding: 3.125rem 2rem;
        .container{
            width: 100% !important;
         }
    }
}