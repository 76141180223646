@import '../../../theme.scss';
.product-banner-wrapper {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 4.5rem;
    padding-top: 7rem;
    background: $lightblue;
 
    

    .product-info-wrapper {
        width: calc(100% - 80px);
        height: auto;
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        padding: 3rem 0;
        margin: 0 auto;
       

        .product-info-detail-wrap {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            justify-content: center;

            .product-info-title {
                width: 100%;
                text-align: center;
                font-size: 3.75rem;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                letter-spacing: -1.2px;

                @media(max-width:1199px) {
                    font-size: 3rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 125%;
                    letter-spacing: -0.96px;

                }

                @media(max-width:991px) {
                    br {
                        display: none;
                    }
                }

                @media(max-width:767px) {
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 133.333%;
                }

            }

            .product-info-description {
                max-width: 576px;
                width: 100%;
                margin: 0 auto;
                text-align: center;
            }
        }

        .product-serch-wrap {
            width: 100%;
            display: flex;
            justify-content: center;
            gap: 1rem;

            .product-search {
                flex: 0 1 255px;

                .form-control {
                    padding: 0.688rem 1.5rem;
                    text-align: center;

                    @media(max-width:400px) {
                        padding: 0.688rem 0.5rem;
                        font-size: 0.85rem;
                    }
                }

            }

            .btn-wrap {
                flex: 0 0 101px;

                @media(max-width:400px) {
                    flex: 0 0 80px;

                    .btn {
                        font-size: 0.85rem;
                        padding: 0.588rem 1rem;
                    }
                }
            }

        }
        @media(max-width:991px){
            margin-top: 0;
            padding-top: 2.5rem;
        }
        @media(max-width:767px){
            margin-top: 0;
            padding-top: 1.5rem;
        }
        
    }



    @media(max-width:991px) {
        gap: 4rem;
        padding-top: 4.4375rem;
       
    }

}