.home-main-wrapper {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 4.5rem;

    .home-info-wrapper {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        margin-top: 3rem;
        padding: 0 1.25rem;

        .home-info-detail-wrap {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            justify-content: center;

            .home-info-title {
                width: 100%;
                text-align: center;
                font-size: 3.75rem;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                letter-spacing: -1.2px;

                @media(max-width:1199px) {
                    font-size: 3rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 125%;
                    letter-spacing: -0.96px;

                }

                @media(max-width:991px) {
                    br {
                        display: none;
                    }
                }

                @media(max-width:767px) {
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 133.333%;
                }

            }

            .home-info-description {
                max-width: 576px;
                width: 100%;
                margin: 0 auto;
                text-align: center;
            }
        }

        .home-btn-wrap {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        @media(max-width:991px){
            margin-top: 1rem;
        }
    }

    .home-img-container {
        width: 100%;
        height: auto;
        overflow: hidden;
        display: flex;
        align-items: center;
        position: relative;

        &::after {
            content: '';
            width: 100%;
            height: 29%;
            position: absolute;
            left: 0;
            bottom: 0;
            background-color: #000;
        }

        .car-container {
            flex: 1;
            position: relative;
            z-index: 2;

            img {
                width: 100%
            }
        }

        .frame-container {
            flex: 1;
            position: relative;
            z-index: 1;

            img {
                width: 100%;
                margin-left: -80px;

                @media(max-width:991px) {
                    margin-left: unset;

                }
            }

        }

        @media(max-width:991px) {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            align-items: flex-end;

            .car-container {
                grid-column: 1/5;
                grid-row: 2/1;
            }

            .frame-container {
                grid-column: 4/-1;
                grid-row: 1/2;
            }
        }

        @media(max-width:767px) {
            .car-container {
                grid-column: 1/4;

            }
        }
    }

    @media(max-width:991px) {
        gap: 4rem;
    }

}