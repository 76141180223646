@import '../../../theme.scss';
.plans-banner-wrapper {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 4.5rem;
    padding-top: 7rem;
    background: $lightblue;
    .plans-info-wrapper {
        width: calc(100% - 80px);
        height: auto;
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        padding: 3rem 0 0;
        margin: 0 auto;
        .plans-info-detail-wrap {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            justify-content: center;
            .plans-info-title {
                width: 100%;
                text-align: center;
                font-size: 3.75rem;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                letter-spacing: -1.2px;

                @media(max-width:1199px) {
                    font-size: 3rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 125%;
                    letter-spacing: -0.96px;
                }
                @media(max-width:991px) {
                    br {
                        display: none;
                    }
                }
                @media(max-width:767px) {
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 133.333%;
                }
            }
            .plans-info-description {
                max-width: 576px;
                width: 100%;
                margin: 0 auto;
                text-align: center;
            }

            .toggle-btn-wrapper{
                max-width:208px;
                width: 208px;
                margin: 0 auto;
                margin-top: 2.125rem;
                position: relative;

                .switches-container {
                    width: 100%;
                    position: relative;
                    display: flex;
                    padding: 0;
                    position: relative;
                    background: #B7D5FF;
                    line-height: 3.25rem;
                    border-radius: 3rem;
                    margin-left: auto;
                    margin-right: auto;
                    input {
                        visibility: hidden;
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        &:nth-of-type(1) {
                            &:checked~.switch-wrapper {
                                transform: translateX(0%);
                                .switch {
                                    div {
                                        &:nth-of-type(1) {
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                        }
                        &:nth-of-type(2) {
                            &:checked~.switch-wrapper {
                                transform: translateX(100%);
                                .switch {
                                    div {
                                        &:nth-of-type(2) {
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    label {
                        width: 50%;
                        padding: 0;
                        margin: 0;
                        text-align: center;
                        cursor: pointer;
                        color: $primary;
                    }
                }
                .switch-wrapper {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    width: 50%;
                    padding: .25rem;
                    z-index:1;
                    transition: transform .5s cubic-bezier(.77, 0, .175, 1);
                }
                .switch {
                    border-radius: 3rem;
                    background:$white;
                    height: 100%;
                    div {
                        width: 100%;
                        text-align: center;
                        opacity: 0;
                        display: block;
                        color: $primary;
                        transition: opacity .2s cubic-bezier(.77, 0, .175, 1) .125s;
                        will-change: opacity;
                        position: absolute;
                        top: 0;
                        left: 0;
                        font-weight: 600;
                    }
                }
                
            }
        }
        @media(max-width:991px){
            margin-top: 0;
            padding-top: 2.5rem;
        }
        @media(max-width:767px){
            margin-top: 0;
            padding-top: 1.5rem;
        }
    }

    @media(max-width:991px) {
        gap: 4rem;
        padding-top: 4.4375rem;
       
    }

}