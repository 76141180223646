@import '../../theme.scss';
footer {
    width: 100%;
    height: auto;
    padding: 5rem 0;

    .footer-wrapper {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 1rem;

        .footer-logo-wrapper {
            flex: 0 1 203px;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            .footer-logo {
                display: flex;
                width: 203px;

                @media(max-width:767px) {
                    width: 135px;
                }
            }

            .legal-info {
                width: 100%;
                font-size: 1rem;
                font-weight: 600;
            }

            @media(max-width:575px) {
                flex: 0 0 100%
            }
        }

        .footer-nav-wrapper {
            flex: 0 0 272px;
            display: flex;
            justify-content: space-between;
            gap: 1rem;

            .footer-nav-wrap {
                flex: 0 0 auto;
                display: flex;
                flex-direction: column;
                gap: 1.25rem;

                .footer-nav-title {
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 700;
                    margin-bottom: 0;
                }

                .footer-nav-list {
                    margin: 0;
                    padding: 0;
                    list-style-type: none;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    li {

                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 1.25rem;
                    
                        span{
                            color: #0A0D12;
                            cursor: pointer;
                            &:hover{
                                color: $primary;
                            }
                        }
                        
                        a{
                            color: #0A0D12;
                            text-decoration: none;
                            &:hover{
                                color: $primary;
                            }
                        }

                    }
                }

            }

            @media(max-width:575px) {
                width: 100%;
                flex: 1 1 100%;
            }
        }

        @media(max-width:575px) {
            flex-direction: column;
            gap: 2.5rem;
        }
    }

    @media(max-width:991px) {
        padding: 2.5rem 0;
    }

    @media(max-width:767px) {
        padding: 2rem 0;
    }
}