@import '../../theme.scss';

header {
    width: 100%;
    height: auto;
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,.4);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
   

    .header-wrapper {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem 0 1rem;
        gap: 1rem;

        .logo {
            flex: 0 0 203px;
            cursor: pointer;

            @media(max-width:991px) {
                flex: 0 0 135px;
            }
        }

        .header-nav-wrap {
            flex: 0 1 auto;
            display: flex;
            display: flex;
            align-items: center;
            gap: 1rem;

            .nav {
                list-style-type: none;
                margin: 0;
                padding: 0;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 2rem;

                li {
                    font-weight: 600;
                    font-size: 1rem;
                    cursor: pointer;

                    .dropdown {
                        .dropdown-toggle {
                            padding: 0;
                            color: currentColor;
                            font-weight: 600;
                            font-size: 1rem;
                            background: transparent;
                            border: unset;

                            &::after {
                                content: unset;
                            }

                            svg {
                                fill: currentColor;
                            }
                        }

                        .dropdown-menu {
                            width: 250px;
                            inset: unset !important;
                            left: 50% !important;
                            transform: translateX(-50%) !important;
                            top: 160% !important;
                            padding: 0.5rem;
                            border-color: transparent;
                            box-shadow: 0 -10px 40px rgba(0, 0, 0, 0.08), 0 6px 6px rgba(0, 0, 0, 0.08) !important;

                            .dropdown-item {
                                padding: 0.1rem 0.5rem;

                                &:hover {
                                    background: transparent;
                                    color: $primary;
                                }
                            }

                        }
                        
                    }

                    &:hover {
                        color: $primary;
                    }

                    &.login-btn-wrap {
                        padding-left: 8px;

                        .btn-outline-secondary {
                            color: $primary;
                            padding: .375rem 1.5rem;
                            font-weight: 600;

                            &:hover {
                                background-color: $primary;
                                color: $white;
                                border-color: $primary;
                            }
                        }
                    }
                    &.active{
                        color: $primary;
                    }

                }

                @media(max-width:991px) {
                    display: none;

                    &.open {
                        display: flex !important;
                        position: fixed;
                        top:60px;
                        left: auto;
                        right: 1.5rem;
                        padding: 2rem 1rem !important;
                        gap: 1rem;
                        width: 350px;
                        margin: auto;
                        z-index: 999;
                        flex-direction: column;
                        padding: 1rem;
                        border-radius: 6px;
                        background-color: $white;
                        box-shadow: 0 -10px 40px rgba(0, 0, 0, 0.08), 0 6px 6px rgba(0, 0, 0, 0.08) !important;

                    }
                }

                @media(max-width:575px) {
                    &.open {
                        left: 0;
                        right: 0;
                        width: 90%;
                    }
                }
            }


            .hamburger {
                display: none;

                @media(max-width:991px) {
                    display: inline-block;
                }
            }

        }

        @media(max-width:991px) {
            padding: 1.25rem 0;
        }
    }
}