.coverage-container-wrap {
    width: 100%;
    height: auto;
    padding-bottom: 5rem;

    .container {
        .coverage-content-wrapper {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            gap: 2rem;

            .coverage-section-title {
                width: 100%;
                height: auto;
                text-align: center;
                color: #0A0D12;
                font-size: 3rem;
                font-style: normal;
                font-weight: 700;
                line-height: 125%;
                letter-spacing: -0.96px;
                @media(max-width:1199px){
                    font-size:2.25rem;
                }
                @media(max-width:767px){
                    font-size:1.5rem;
                }
            }

            .cc-container {
                width: 100%;
                height: auto;
                border: 1px solid #E7E7E7;
                border-radius: 1.125rem;
                padding: 1.5rem;
                display: flex;
                gap: 1.5rem;

                .cc-map-wraper {
                    flex: 0 1 750px;
                    height: 379px;
                    position: relative;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #E7E7E7;
                    border-radius: 1rem;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                    @media(max-width:767px){
                        height: 240px; 
                        flex:unset;
                    }
                }

                .cc-country-wrap {
                    flex: 0 1 458px;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    .cc-country-title {
                        font-size: 1.5rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 133.333%;
                        color: #0A0D12;
                        @media(max-width:767px){
                            font-size: 1.25rem; 
                        }
                    }

                    .country-list-wrap {
                        width: 100%;
                        flex: 1;
                        border: 1px solid #E7E7E7;
                        border-radius: 1rem;
                        padding: 1rem;
                        display: flex;
                        flex-direction: column;
                        gap: 1rem;

                        .country-list-title {
                            font-size: 1.125rem;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 155.556%;
                            color: #0A0D12;
                        }

                        .country-list {
                            width: 100%;
                            display: flex;
                            flex-wrap: wrap;
                            overflow: hidden;
                            flex: 1;
                            justify-content: flex-start;
                            gap: 1rem;
                            padding-right: 1rem;

                            .country-list-row {
                                width: calc(50% - 0.5rem);
                                display: flex;
                                gap: .75rem;

                                .country-icon {
                                    flex: 0 0 24px;
                                    height: 24px;
                                    border-radius: 24px;
                                    border: 1px solid #E7E7E7;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;

                                    img {
                                        border-radius: 24px;
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                        object-position: center;
                                    }
                                }

                                .country-name {
                                    flex: 1;
                                    font-size: 1rem;
                                    color: #0A0D12;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 150%;
                                }

                                @media(max-width:1199px){
                                    width: 100%;
                                }

                            }

                        }

                    }
                    @media(max-width:767px){
                       gap:0.5rem;
                       flex: unset;
                       height: 250px;
                    }
                }

                @media(max-width:767px){
                    flex-direction: column;
                    padding: 1rem;
                }
            }
        }
    }

    @media(max-width:991px){
        padding-bottom:2.5rem;
    }
    @media(max-width:767px){
        padding-bottom:2rem;
    }
}