@import '../../../theme.scss';

.plans-details-wrapper {
    width: 100%;
    height: auto;
    padding: 5rem 0;
    position: relative;
    &:after{
        content: '';
        width: 100%;
        height: 428px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background:$lightblue;
    }

    .container {
        position: relative;
        z-index: 2;

        .plans-detail-box-wrapper {
            width: 100%;
            height: auto;
            display: flex;
            flex-wrap: wrap;
            gap: 1.5rem;

            .plans-detail-box {
                width: calc(33.333333% - 1.5rem);
                border: 1px solid #E7E7E7;
                padding: 1.5rem;
                border-radius: 1.125rem;
                background: $white;
                display: flex;
                flex-direction: column;
                gap: 1.5rem;

                .plans-detail-content {
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    gap: 2rem;
                    padding-bottom: 4.375rem;

                    .plans-title {
                        font-size: 1.5rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 133.333%;
                        color: $primary;
                        border-bottom: 1px solid #E7E7E7;
                        text-align: center;
                        padding-bottom: .75rem;
                    }

                    .plan-price-wrap {
                        width: 100%;
                        display: flex;
                        flex-direction: column;

                        .plan-price {
                            width: 100%;
                            text-align: center;
                            font-size: 2.25rem;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 122.222%;
                            letter-spacing: -0.72px;
                            color: #0A0D12;
                        }

                        .plan-duration {
                            font-size: 1.5rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 133.333%;
                            color: #B6B6B8;
                            text-align: center;
                        }

                    }

                    .feature-list-wrap {
                        width: 100%;
                        height: auto;
                        display: flex;
                        flex-direction: column;
                        gap: .75rem;

                        .fl-row {
                            width: 100%;
                            height: auto;
                            display: flex;
                            gap: 0.75rem;

                            .fl-icon-wrap {
                                flex: 0 0 24px;
                                width: 24px;
                                height: 24px;
                                border-radius: 24px;
                                background-color: $lightblue;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                svg {
                                    width: 10px;
                                }
                            }

                            .fl-text-wrap {
                                flex: 1;
                                font-size: 1.125rem;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 155.556%;
                            }
                        }
                    }
                }

                .plans-btn-wrap {
                    width: 100%;
                    margin-top: auto;
                    .btn{

                   
                    &.btn-secondary {
                        width: 100%;
                        background: $lightblue;
                        border-color: $lightblue;
                        color: $primary;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 0.5rem;

                        svg {
                            fill: currentColor;
                        }

                        &:hover {
                            background: $primary;
                            color: $white;
                            border-color: $primary;
                        }
                    }
                    &.btn-primary {
                        width: 100%;
                        
                       
                        color: $white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 0.5rem;

                        svg {
                            fill: currentColor;
                        }

                        
                    }
                }
                }

                &.best-deal {
                    padding-top: 3.75rem;
                    position: relative;
                    overflow: hidden;

                    .best-deal-wrap {
                        background: $primary;
                        color: $white;
                        text-align: center;
                        width: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 1;
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 150%;
                        padding: .25rem 0.5rem;
                    }

                }
                @media(max-width:767px){
                   width: 100%;
                }
            }

            @media(max-width:767px){
                flex-direction: column;
            }
        }
    }

    @media(max-width:991px){
        padding: 4rem 0;
    }

    @media(max-width:991px){
        padding: 2.5rem 0;
    }

}