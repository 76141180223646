@import '../../../theme.scss';

.purchase-container {
    width: 100%;
    height: auto;
    padding: 2.5rem 0;
    &.service{
        background: none !important;
    }

    .purchase-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        .purchase-link {
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.09px;
            text-align: center;

            span {
                color: $primary;
                text-decoration: underline;
                cursor: pointer;

                &:hover {
                    text-decoration: none;
                }

                @media(max-width:650px) {
                    width: 100%;
                    display: block;
                }
            }
        }

    }

    .car-img-wrap {
        width: 100%;
        height: auto;
        display: none;

        @media(max-width:767px) {
            display: flex;
            justify-content: flex-end;
        }

    }

    
}