@import '../../theme.scss';

.contact-wrapper-main {
    width: 100%;
    background: $lightblue;
    padding-top: 7rem;

    .contact-wrapper {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: flex-start;
        gap: 8.3125rem;
        align-items: center;
        padding: 4.375rem 0 8.375rem;

        .contact-left {
            flex: 0 0 516px;
            display: flex;
            flex-direction: column;
            gap: 2.5rem;

            .contact-title-wrapper {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                gap: 1rem;

                .contact-title {
                    font-size: 3.75rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    letter-spacing: -1.2px;

                    @media(max-width:1199px) {
                        font-size: 3rem;
    
                    }

                    @media(max-width:767px) {
                        font-size: 1.5rem;
                        text-align: center;
                    }
                }

                .contact-description {
                    width: 100%;
                    display: block;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;

                    @media(max-width:991px) {

                        text-align: center;
                    }

                }

            }

            .our-contact-wrap {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                gap: 1rem;

                .our-contact-title {
                    width: 100%;
                    height: auto;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 150%;

                    @media(max-width:991px) {

                        text-align: center;
                    }

                }

                .our-contacts {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: 1rem;

                    .contact-tag {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 0.2rem;
                        padding: 0.5rem 1.25rem;
                        background-color: $white;
                        border-radius: 4rem;
                        color: $primary;
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 150%;

                        svg {
                            fill: currentColor
                        }
                    }

                    @media(max-width:991px) {

                        justify-content: center;
                        flex-wrap: wrap;
                    }
                }
            }

            @media(max-width:991px) {
                flex: 1 1 100%;
                align-items: center;
                width: 100%;
            }

            @media(max-width:767px) {
                gap: 1.5rem;
            }
        }

        .contact-right {
            flex: 0 1 519px;

            .contact-form-wrap {
                background: $white;
                padding: 1rem;
                border-radius: 1rem;
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 1.5rem;

                .form-row {
                    width: 100%;
                    height: auto;

                    textarea {
                        border-radius: 12px !important;
                        height: 167px;
                        resize: none;
                    }

                    .form-label {
                        width: 100%;
                        font-size: 1.125rem;
                        margin-bottom: 0.6rem;
                    }

                   
                }
            }

            @media(max-width:991px) {
                width: 100%;
                flex: 1 1 100%
            }
        }

        @media(max-width:1400px) {
            padding: 2.375rem 0 4.375rem;
            gap: 4.3125rem;
        }

        @media(max-width:991px) {
            flex-direction: column;
            padding: 2.375rem 0 4.375rem;
            gap: 4.3125rem;
        }

        @media(max-width:767px) {
            flex-direction: column;
            padding: 1.5rem 0 4.375rem;
            gap:2rem;
        }

    }

    @media(max-width:767px) {
        padding-top: 4.4375rem;
    }

}