@import '../../../theme.scss';

.order-banner-container {
    width: 100%;
    height: auto;
    background: $lightblue;
    padding: 7rem 0 1.875rem;

    .order-banner {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        padding: 5rem 0;

        .ob-content-wrap {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;

            .order-container {
                padding-left: calc((100% - 1280px) / 2);
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                overflow: hidden;
                gap: 2rem;

                .ob-title-wrapper {
                    width: 630px;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    .ob-title {
                        font-size: 3.75rem;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 120%;
                        letter-spacing: -1.2px;

                        @media(max-width:1199px) {
                            font-size: 3rem;
                        }

                        @media(max-width:767px) {
                            font-size: 1.5rem;
                            text-align: center;

                            br {
                                display: none;
                            }
                        }
                    }

                    .ob-subtitle {
                        font-size: 1rem;

                        @media(max-width:767px) {

                            text-align: center;
                        }
                    }

                    @media(max-width:991px) {
                        width: 500px;
                    }

                    @media(max-width:767px) {
                        width: 100%;
                    }
                }

                @media(max-width:1330px) {
                    padding-left: 40px;
                }
        
                @media(max-width:991px) {
                    .car-img-wrap {
                        margin-right: -95px;
        
                        img {
                            height: 190px;
                        }
        
                    }
        
                }
        
                @media(max-width:767px) {
                    padding: 0 40px;
        
                    .car-img-wrap {
                        display: none;
                    }
                }
            }

           

        }

        @media(max-width:1199px) {
            padding: 2rem 0;

        }

        @media(max-width:991px) {
            padding: 0;
        
        }
    }
}