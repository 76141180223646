@import '../../../theme.scss';

.product-list-wrapper {
    width: 100%;
    height: auto;

    padding: 5rem 0;
    position: relative;

    &:after {
        content: '';
        width: 100%;
        height: 467px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background: $lightblue;
        @media(max-width:991px){
            height: 430px; 
        }
        @media(max-width:767px){
            height: 360px; 
        }
    }

    .container {
        position: relative;
        z-index: 2;


        .product-title-wrap {
            display: flex;
            justify-content: center;
            font-size: 3rem;
            font-style: normal;
            font-weight: 700;
            line-height: 125%;
            letter-spacing: -0.96px;
            color: #0A0D12;
            width: 100%;

            @media(max-width:1199px) {
                font-size: 2.25rem;
            }

            @media(max-width:767px) {
                font-size: 1.5rem;
            }
        }

        .product-box-wrapper {
            width: 100%;
            display: flex;
            justify-content: center;
            gap: 1.5rem;
            flex-wrap: wrap;
            margin-top: 2rem;

            .product-box {
                width: calc(33.333333% - 1.5rem);
                border: 1px solid #E7E7E7;
                padding: 1rem;
                border-radius: 1.125rem;
                background: $white;
                display: flex;
                flex-direction: column;
                gap: 1.5rem;

                .product-tag {
                    width: 100%;

                    span {
                        border-radius: 4rem;
                        padding: .625rem 1.5rem;
                        font-size: 1rem;
                        font-weight: 600;
                        border: 1px solid #E7E7E7;
                        color: $primary;
                        display: inline-block;
                        line-height: 150%;
                    }
                }

                .product-img-wrap {
                    width: 100%;
                    height: 217px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        object-fit: cover;
                        object-position: center;
                    }
                }

                .product-detail-wrap {
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    .product-detail-title-wrap {
                        width: 100%;
                        height: auto;
                        display: flex;
                        flex-direction: column;

                        .product-detail-title {
                            font-size: 1.5rem;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 133.333%;
                            letter-spacing: -0.96px;
                        }

                        .product-detail-subtitle {
                            font-size: 1rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 150%;
                            color: #6C6E71;
                        }
                    }

                    .product-list-wrap {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: .25rem;

                        .product-list-row {
                            width: 100%;
                            display: flex;
                            gap: .5rem;

                            .product-icon-wrap {
                                flex: 0 0 8px;
                            }

                            .product-list {
                                flex: 1;
                                font-size: 1rem;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 150%;
                            }
                        }
                    }
                }

                .learn-more {
                    margin-top: auto;
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;

                    span {
                        color: $primary;
                        font-size: 1rem;
                        text-decoration: underline;
                        cursor: pointer;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }

                @media(max-width:991px) {
                    width: calc(50% - 1.5rem);
                }

                @media(max-width:767px) {
                    width: 100%;
                }
            }
        }

        @media(max-width:991px) {
            padding: 4rem 0;
        }
    }

    @media(max-width:767px) {
        padding: 2.5rem 0;
    }
}