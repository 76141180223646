@import '../../theme.scss';


.product-detail-top-wrapper {
    width: 100%;
    height: auto;
    background: $lightblue;
    padding: 8rem 0 4.375rem;

    .product-detail-top {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1.5rem;

        .product-detail-left {
            flex: 0 1 628px;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            .btn-wrap {
                .btn-light {
                    border-color: #E7E7E7;
                    background-color: $white;
                    display: flex;
                    align-items: center;
                    gap: 0.8rem;
                    color: $primary;

                    svg {
                        fill: currentColor;
                    }

                    &:hover {
                        background: $primary;
                        color: $white;
                        border-color: $primary;
                    }
                }
            }

            .product-detail {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                gap: 0.5rem;

                .productid {
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 150%;
                    color: $primary;
                }

                .product-name {
                    font-size: 3.75rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    letter-spacing: -1.2px;

                    @media(max-width:1199px) {
                        font-size: 3rem;
                    }

                    @media(max-width:767px) {
                        font-size: 1.5rem;
                        line-height: 133.333%;
                    }
                }

                .product-description {
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                }
            }

            @media(max-width:991px) {
                flex: 1 1 100%;
                width: 100%;
            }
        }

        .product-img-wrap {
            flex: 0 1 628px;
            display: flex;
            justify-content: center;
            align-items: center;

            @media(max-width:991px) {
                flex: 1 1 100%;
                width: 100%;
            }
        }

        @media(max-width:991px) {
            flex-direction: column;
        }
    }
}

.key-feature-container {
    width: 100%;
    height: auto;
    position: relative;

    &:after {
        content: '';
        width: 100%;
        height: 169px;
        background: $lightblue;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }

    .key-feature-wrapper {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        gap: 4rem;

        .kf-section-title {
            width: 100%;
            height: auto;
            text-align: center;
            font-size: 3rem;
            font-style: normal;
            font-weight: 700;
            line-height: 125%;
            letter-spacing: -0.96px;

            @media(max-width:1199px) {
                font-size: 2.25rem;
                line-height: 122.222%;
            }
        }

        .kf-row {
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            gap: 2.5rem;


            .kf-img-wrap {
                flex: 0 1 620px;
                border-radius: 1.5rem;
                border: 1px solid #E7E7E7;
                background: $white;
                height: 360px;
                position: relative;
                overflow: hidden;

                .fearure-icon {
                    width: 96px;
                    height: 96px;
                    border-radius: 100%;
                    padding: 1rem;
                    background: $lightblue;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 1rem;
                    left: 1rem;

                    svg {
                        width: 100%;
                    }

                    &.right {
                        left: unset;
                        right: 1rem;
                    }
                    @media(max-width:1199px) {
                        width: 64px;
                        height: 64px;
                        padding: .75rem;

                    }

                    @media(max-width:767px) {
                        width: 44px;
                        height: 44px;
                        padding: 0.5rem;

                    }
                }

                >img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                @media(max-width:991px) {
                    flex: 1 1 100%;
                    width: 100%;
                }

                @media(max-width:767px) {
                    height: 200px;
                }
            }

            .kf-content-wrap {
                flex: 0 1 620px;
                display: flex;
                flex-direction: column;
                gap: .75rem;

                .kf-title {
                    font-size: 2.25rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 122.222%;
                    letter-spacing: -0.72px;

                    @media(max-width:1199px) {
                        font-size: 1.875rem;
                        line-height: 126.667%;
                    }

                    @media(max-width:767px) {
                        font-size: 1.25rem;
                        line-height: 150%;
                    }
                }

                .kf-description {
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                }

                @media(max-width:991px) {
                    flex: 1 1 100%;
                    width: 100%;
                }
            }

            @media(max-width:1199px) {
                gap: 1.5rem;
            }

            @media(max-width:991px) {
                flex-direction: column;

            }

            @media(max-width:767px) {
                flex-direction: column;
                gap: 1rem;
            }
        }

        @media(max-width:1199px) {
            gap: 2.5rem;

        }

        @media(max-width:767px) {
            gap: 2rem;

        }
    }

    @media(max-width:1199px) {
        padding-top: 2.5rem;

        
    }

    @media(max-width:991px) {
        padding-top: 2.5rem;

        &::after {
            content: none;
        }
    }

}