@import '../../theme.scss';

.signup-form-wrap {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-bottom: 3rem;

    .signup-form {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .form-label {
            width: 100%;
            margin-bottom: 0.6rem;
            font-size: 1.125rem;
        }
        textarea{
            border-radius:.75rem;
            resize: none;
            height: 137px;
        }
    }

   

    .btn-wrap {
        width: 100%;
        height: auto;

        .btn {
            width: 100%;
        }
    }

    .have-an-account-wrapper {
        width: 100%;
        height: auto;
        text-align: center;
        font-size: 1rem;
        span {
            color: $primary;
            text-decoration: underline;
            cursor: pointer;
            &:hover {
                text-decoration: none;
            }
        }
    }
}